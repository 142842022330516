import React, { useEffect, useState } from "react";
import "./navbar.css";
import { Grid, Container, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../SharedImages/ah_logo.svg';


const NavBar = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isActive, setActive] = useState(false);

    let navBarClass = 'navbar';
    let navBarMobileClass = 'navbar-mobile';
    let mobileMenu = 'navbar-mobile-menu';
    let menuToggle = 'navbar-mobile-toggle';

    if (scrollPosition > 70) {
        navBarClass += ' active';
    }
    
    const onScroll = (event) => {
        setScrollPosition(event.target.documentElement.scrollTop)
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [])

    const handleToggle = () => {
        setActive(!isActive);
    };

    if(isActive) {
        mobileMenu += ' active'
        menuToggle += ' active'
    }

    const NavItems = () => {
        return (
            <>
              
            </>
        );
    };

    return (
        <>
            <div className={navBarClass}>
                <Container>
                    <Hidden smDown>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={2}>
                                <Link to="/"><Logo /></Link>
                            </Grid>
                            <Grid id="nav-items" item xs={8}>
                                <NavItems />
                            </Grid>
                        </Grid>
                    </Hidden>
                </Container>
            </div>

            <div className={navBarMobileClass}>
                <div className={mobileMenu}>
                    <NavItems />
                </div>
                <div id="app-bar">
                    <Link to="/"><Logo /></Link>
                </div>
            </div>
        </>
    );
};


export default NavBar;