import React, { Component } from "react";
import "./css/Main.css";
import { Route, Redirect } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';

//////////////////////
// Pages Definition //
//////////////////////

import HomePage from "./pages/Home/home";
import NotFoundPage from "./pages/404/404";
import Page from "./components/Page/page";


class App extends Component {
  render() {
    return (
      <AnimatedSwitch
        atEnter={{ opacity: 0, marginTop: 20}}
        atLeave={{ opacity: 0, marginTop: 0}}
        atActive={{ opacity: 1, marginTop: 0}}
        className="switch-wrapper"
      >
        <Route exact path="/" render={(props) => ( <Page title="Aurore Hello"> <HomePage {...props} /> </Page> )} />
        <Route exact path="/404" render={(props) => ( <Page title="Aurore Hello | 404"> <NotFoundPage {...props} /> </Page> )} />
        <Redirect to="/404" />
        </AnimatedSwitch>
    )
  };
};

export default App;
