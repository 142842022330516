import React from "react";
import "./404.css";
import Footer from "../../components/Footer/footer";
import {Grid, Container, Button} from "@material-ui/core";
import { Link } from "react-router-dom";

const NotFoundPage = () => {

    return (
        <div className="switch-wrapper">
            <div id="errorpage-container">
                <Container>
                    <Grid item md={12}>
                        <h1><span role="img" aria-label="Sad Emoji">😔</span></h1>
                        <h1>Oops !</h1>
                        <p>Cette page n'existe pas, veuillez retourner à la page d'accueil.</p>
                        <Grid item sm={12} md={4}>
                            <Link to="/">
                                <Button fullWidth type="button" variant="contained" className="button" color="primary" >Page d'accueil</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default NotFoundPage;