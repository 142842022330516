import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import NavBar from './components/NavBar/navbar';
import ScrollToTop from './components/ScrollToTop/scrolltotop';
import {IntlProvider} from "react-intl";
import lang_en from "./translation/locales/en";

// when translations will be available
//var language = navigator.language.split(/[-_]/)[0];
// const messages = {
//   'en': lang_en
// };

ReactDOM.render(
  <IntlProvider messages={lang_en} locale="en" defaultLocale="en">
    <Router>
      <ScrollToTop />
        <NavBar />
        <Route path="/" component={App} />
    </Router>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
