import React from "react";
import "./home.css";
import Footer from "../../components/Footer/footer";
import {Grid, Container, Button} from "@material-ui/core";
import AuroreHelloTitle from '../../components/SharedImages/aurorehellotitle.png';
import ScrollDown from './images/scrolldown.svg';
import VisitWebsite from './images/visitwebsite.svg';
import { ReactComponent as Divider } from './images/divider.svg';
import ImageGallery from 'react-image-gallery';

import { ReactComponent as NelliganLogos } from './images/nelligan_logos.svg';
import { ReactComponent as MRestaurantsLogo } from './images/mrestaurants_logo.svg';
import { ReactComponent as FredericksLogo } from './images/fredericks_logo.svg';
import { ReactComponent as GermanLogos } from './images/german_logos.svg';
import { ReactComponent as MazeLogos } from './images/maze_logos.svg';
import { ReactComponent as AvenueLogos } from './images/avenue_logos.svg';
import { ReactComponent as NMLogos } from './images/nm_logos.svg';
import { ReactComponent as BarriereLogos } from './images/barriere_logos.svg';
import { ReactComponent as RivageLogos } from './images/rivage_logos.svg';

const imagesNelligan = [
    {
      original: 'https://aurorehello.com/images/nelligan1.jpg',
    },
    {
      original: 'https://aurorehello.com/images/nelligan2.png',
    },
    {
      original: 'https://aurorehello.com/images/nelligan3.png',
    },
  ];

const imagesM = [
    {
        original: 'https://aurorehello.com/images/M1.png',
    },
    {
        original: 'https://aurorehello.com/images/M2.png',
    },
    {
        original: 'https://aurorehello.com/images/M3.png',
    },
    {
        original: 'https://aurorehello.com/images/M4.png',
    },
];

const imagesFredericks = [
    {
        original: 'https://aurorehello.com/images/Fred1.png',
    },
    {
        original: 'https://aurorehello.com/images/Fred2.png',
    },
    {
        original: 'https://aurorehello.com/images/Fred3.png',
    },
    {
        original: 'https://aurorehello.com/images/Fred4.jpeg',
    },
]

const imagesGerman = [
    {
        original: 'https://aurorehello.com/images/GG1.png',
    },
    {
        original: 'https://aurorehello.com/images/GG2.png',
    },
]

const imagesMaze = [
    {
        original: 'https://aurorehello.com/images/maze1.png',
    },
    {
        original: 'https://aurorehello.com/images/maze2.png',
    },
]

const imagesAvenue = [
    {
        original: 'https://aurorehello.com/images/Avenue1.png',
    },
    {
        original: 'https://aurorehello.com/images/Avenue2.png',
    },
]

const imagesNouveauMonde = [
    {
        original: 'https://aurorehello.com/images/NM1.png',
    },
    {
        original: 'https://aurorehello.com/images/NM2.png',
    },
    {
        original: 'https://aurorehello.com/images/NM3.png',
    },
]

const imagesBarriere = [
    {
        original: 'https://aurorehello.com/images/Barriere1.png',
    },
    {
        original: 'https://aurorehello.com/images/Barriere2.png',
    },
    {
        original: 'https://aurorehello.com/images/Barriere3.png',
    },
]

const imagesRivage = [
    {
        original: 'https://aurorehello.com/images/Rivage1.jpeg',
    },
    {
        original: 'https://aurorehello.com/images/Rivage2.jpeg',
    },
    {
        original: 'https://aurorehello.com/images/Rivage3.jpg',
    },
    {
        original: 'https://aurorehello.com/images/Rivage4.jpg',
    },
    {
        original: 'https://aurorehello.com/images/Rivage5.jpg',
    },
]

const HomePage = () => {

    return (
        <div className="switch-wrapper">
            <div id="home-hero">
                <Container>
                    <Grid item md={6}>
                        <img width="300px" src={AuroreHelloTitle} alt="Logo" className="aurorehellotitle"/>
                    </Grid>
                    <Grid item md={6}>
                        <div id="monparcours">
                            <h3>Mon parcours <br /> <span>en détails</span></h3>
                        </div>
                        <div id="scrolldown">
                            <img src={ScrollDown} alt="Scroll Down" />
                        </div>
                    </Grid>
                </Container>
            </div>

            {/* /////////// RIVAGE /////////// */}

            <div id="rivage">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <RivageLogos className="logossvg" />
                            <h1>Rivage Hôtel & Spa 4*  <a href="https://www.rivage-hotel.com/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="Rivage Hôtel & Spa 4*" /></a></h1>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <p><span>Best Mont Blanc</span> • Annecy (France)</p>
                                    <p>Rivage Hôtel & Spa a ouvert ses portes en Juillet 2021. Il se compose de chambres contemporaines et chaleureuses, d'un espace spa NUXE haut de gamme, d'une salle de fitness et d'un restaurant à la cuisine cosmopolite, de saison et locale, "Le Ricochet".</p>
                                </Grid>

                                <Grid item md={8}>
                                    <div className="position noreco">
                                        <p>De 2021 à ce jour • <span>Responsable des Evénements</span></p>
                                        <p>
                                            Dans le contexte d’ouverture de ce nouvel établissement Annécien, j’ai été recrutée dans la perspective de développer le service des évènements.< br/>
                                            Conjointement à mes tâches de responsable de service, j'avais en charge la gestion des relations clients du premier contact à la facturation. Je devais également m'assurer de la satisfaction client et accroître le chiffre d’affaire de l’établissement en proposant des services et prestations supplémentaires.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesRivage} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <div className="extrainformations">
                                <h3>Le Rivage <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Hôtel : 123 Chambres</li>
                                        <li>4 salles de réunion : groupes de 10 à 200 personnes.</li>
                                        <li>Restaurant "Le Ricochet" : capacité 150 personnes en intérieur et 100 sur la terrasse.</li>
                                        <li>Espace bien-être NUXE de 1000m²</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>

            {/* /////////// NELLIGAN /////////// */}

            <div id="nelligan">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <NelliganLogos className="logossvg" />
                            <h1>Hôtel Nelligan 4*  <a href="https://hotelnelligan.com/fr/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="Nelligan" /></a></h1>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <p><span>Groupe Antonopoulos</span> • Montréal (Québec)</p>
                                    <p>Ce luxueux boutique  hôtel se situe au sein du quartier historique du Vieux Montréal. Établissement de charme et de caractère, il a été désigné 5ème meilleur hôtel au Canada en 2020 par le Condé Nast Traveller.</p>
                                </Grid>

                                <Grid item md={8}>
                                    <div className="position">
                                        <p>2019-2021 • <span>Convention Services Manager</span></p>
                                        <p>
                                            Responsable de la planification et de l’organisation des espaces "banquets" au sein de l’établissement. A ce titre, j’avais en charge l’ensemble des activités de ventes des 
                                            banquets pour les groupes, incluant la prospection, le suivi auprès des clients ainsi que la mise à jour des outils opérationnels des ventes. J’ai eu l’opportunité de collaborer avec des entreprises évoluant dans des domaines variés, tels que 
                                            l’industrie pharmaceutique, la santé, l’automobile ou encore la mode.
                                        </p>
                                        <Button target="blank" fullWidth type="button" variant="contained" className="button" color="primary" href="https://aurorehello.com/letters/ah_nelligan1.pdf" >Lettre de recommandation</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesNelligan} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <div className="extrainformations">
                                <h3>Le Nelligan <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Hôtel : 105 Chambres</li>
                                        <li>4 salles de réunions et 1 salle de bal : groupes de 10 à 350 personnes.</li>
                                        <li>"Verses Bistrot" : 90 personnes</li>
                                        <li>Restaurant "Méchant Bœuf" : capacité 120 personnes.</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>
            

            {/* /////////// M RESTAURANTS /////////// */}

            <div id="mrestaurants">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <MRestaurantsLogo className="logossvg" />
                            <h1>Restaurants "M"  <a href="https://mrestaurants.co.uk/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="M Restaurants" /></a></h1>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <p><span>Deux établissements</span> • Londres (GB)</p>
                                    <p>
                                        Ces deux restaurants Londoniens, l’un situé dans la City et l’autre à proximité de la Gare Victoria, offrent à leur clientèle une expérience culinaire inspirée par la gastronomie de 6 pays.
                                        Quant à la carte des vins, la sélection en est assurée par Zack Charilaou, élu meilleur sommelier UK en 2015.
                                    </p>
                                </Grid>

                                <Grid item md={8}>
                                    <div className="position">
                                        <p>2018-2019 • <span>Senior Event Assistant</span></p>
                                        <p>
                                            Suite à mon embauche en tant qu’Event Assistant, j’ai été promue 5 mois plus tard au poste de Senior Event Assistant. J'avais pour objectif de promouvoir et de valoriser les 
                                            prestations proposées par les salles privées des deux établissements, tout en assurant mes responsabilités en événementiel. Dans ce contexte, nous avons multiplié par trois le nombre d’évènements en 
                                            période creuse et par cinq à l’occasion des fêtes de fin d’année.
                                        </p>
                                        <Button target="blank" fullWidth type="button" variant="contained" className="button" color="primary" href="https://aurorehello.com/letters/ah_mrestaurants.pdf" >Lettre de recommandation</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesM} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="extrainformations">
                                <h3>Restaurants M <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Restaurant "M Threadneedle"
                                            <ul>
                                                <li>500 couverts</li>
                                                <li>7 salles privées</li>
                                            </ul>
                                        </li>
                                        <li>Restaurant "M Victoria"
                                            <ul>
                                                <li>400 couverts</li>
                                                <li>6 salles privées</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>
            

            {/* /////////// FREDERICKS /////////// */}

            <div id="fredericks">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <FredericksLogo className="logossvg" />
                            <h1>Restaurant "Frederick’s"  <a href="https://www.fredericks.co.uk/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="Frederick's" /></a></h1>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <p>Londres (GB)</p>
                                    <p>
                                        Frederick’s est un élégant restaurant familial (détenu par la même famille depuis 1969) dans le borough d’Islington, à proximité de la station de métro Angel.
                                    </p>
                                </Grid>

                                <Grid item md={8}>
                                    <div className="position">
                                        <p>2017-2018 • <span>Event planner</span></p>
                                        <p>
                                            J’avais la responsabilité d’organiser les réceptions pour les groupes de 10 à 60 personnes, principalement des événements familiaux (mariages, baptêmes et communions…)
                                            Cette fonction nécessitait de ma part adaptabilité, créativité et rigueur pour satisfaire aux demandes et souhaits spécifiques et variés des clients.
                                        </p>
                                        <Button target="blank" fullWidth type="button" variant="contained" className="button" color="primary" href="https://aurorehello.com/letters/ah_fredericks.pdf" >Lettre de recommandation</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesFredericks} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="extrainformations">
                                <h3>Le Frederick’s <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Restaurant : 100 couverts</li>
                                        <li>Terrasse : 50 couverts</li>
                                        <li>Club Room : 60 couverts</li>
                                        <li>Clarence room : 40 couverts</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>


            {/* /////////// GERMAN /////////// */}

            <div id="german">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <GermanLogos className="logossvg" />
                            <h1>Restaurant "German Gymnasium" <a href="https://www.germangymnasium.com/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="German Gymnasium" /></a></h1>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <p><span>Groupe D&D</span> • Londres (GB)</p>
                                    <p>
                                        Idéalement situé au cœur de King's Cross, le German Gymnasium articule ses activités autour d’un Grand Café recevant la clientèle toute la journée, un restaurant raffiné au premier étage servant à 
                                        midi et le soir, deux bars ouvrant sur une terrasse extérieure.
                                        En 2015, lors de la compétition « Restaurant & Bar Design Awards », le German Gymnasium a reçu le prix du « Best overall Restaurant ».
                                    </p>
                                </Grid>

                                <Grid item md={8}>
                                    <div className="position">
                                        <p>2016-2017 • <span>Event co-ordinator</span></p>
                                        <p>
                                            J’avais la charge d’assister la "Responsable d’évènements" lors de la prise de contact avec la clientèle, d’assurer les visites et d’organiser les dégustations de menus, 
                                            ainsi que de garantir le bon déroulement des évènements.
                                        </p>
                                        <Button target="blank" fullWidth type="button" variant="contained" className="button" color="primary" href="https://aurorehello.com/letters/ah_germangymnasium.pdf" >Lettre de recommandation</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesGerman} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="extrainformations">
                                <h3>Le German Gymnasium <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Café : 120 couverts</li>
                                        <li>Restaurant : 100 couverts</li>
                                        <li>Terrasse : 80 couverts</li>
                                        <li>Salle privée : 32 couverts</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>


            {/* /////////// MAZE /////////// */}

            <div id="maze">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <MazeLogos className="logossvg" />
                            <h1>Restaurant "Maze" <a href="https://www.gordonramsayrestaurants.com/restaurants-and-bars/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="Gordon Ramsay Restaurants" /></a></h1>
                            <hr />
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <p><span>Gordon Ramsay Group</span> • Londres (GB)</p>
                                    <p>
                                        Le restaurant étoilé de Gordon Ramsay était implanté dans le quartier de Mayfair et proposait une expérience culinaire unique, orientée sur les saveurs françaises et asiatiques.
                                        Après 14 années à l'avant-garde de la scène gastronomique londonienne, le Maze a fermé ses portes en Février 2019.
                                    </p>
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}>
                                    <div className="position noreco">
                                        <p>2015-2016 • <span>Réceptionniste</span></p>
                                        <p>Référence: Sandro A. anciennement Assistant General Manager<br /><span className="grey">Pour prise de référence, veuillez me contacter.</span></p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesMaze} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="extrainformations">
                                <h3>Le Maze <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Maze : 120 couverts</li>
                                        <li>Maze Grill : 90 couverts</li>
                                        <li>Salle privée : 12 couverts</li>
                                        <li>Table du chef : 5 couverts</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>


            {/* /////////// AVENUE /////////// */}

            <div id="avenue">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <AvenueLogos className="logossvg" />
                            <h1>Restaurant "Avenue" <a href="https://www.avenue-restaurant.co.uk/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="Avenue Restaurant" /></a></h1>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <p><span>Groupe D&D</span> • Londres (GB)</p>
                                    <p>
                                        L'Avenue est une brasserie moderne localisée au cœur de St. James' street, à proximité du Palais de Buckingham à Londres.
                                        Les clients ont le plaisir de déguster une cuisine alliant la gastronomie classique de la Grande Bretagne à celle plus contemporaine (moderne) des États-Unis.
                                    </p>
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}>
                                    <div className="position">
                                        <p>2015 • <span>Réceptionniste</span></p>
                                        <Button target="blank" fullWidth type="button" variant="contained" className="button" color="primary" href="https://aurorehello.com/letters/ah_avenue.pdf" >Lettre de recommandation</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesAvenue} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="extrainformations">
                                <h3>L'Avenue <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Restaurant : 120 couverts</li>
                                        <li>Salle privée : 30 couverts</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>


            {/* /////////// NOUVEAU MONDE /////////// */}

            <div id="nm">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <NMLogos className="logossvg" />
                            <h1>Hôtel "Le Nouveau Monde" 4* <a href="https://www.hotel-le-nouveau-monde.fr/" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="Hotel Le Nouveau Monde" /></a></h1>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <p><span>Les Thermes Marins de Saint Malo</span> • Saint-Malo (35)</p>
                                    <p>
                                        La décoration de cet hôtel 4 étoiles propose une évasion dans d’autres lieux et d’autres temps, à la découverte de l’histoire des grands navigateurs, corsaires et écrivains de Saint-Malo (Surcouf, 
                                        Jacques Cartier, Chateaubriand…) et des aventuriers découvreurs de nouveaux mondes (Christophe Colomb, Marco Polo, …).
                                    </p>
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}>
                                    <div className="position">
                                        <p>2013-2015 • <span>Réceptionniste</span></p>
                                        <Button target="blank" fullWidth type="button" variant="contained" className="button" color="primary" href="https://aurorehello.com/letters/ah_nouveaumonde.pdf" >Lettre de recommandation</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesNouveauMonde} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="extrainformations">
                                <h3>Le Nouveau Monde <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Hôtel : 83 chambres</li>
                                        <li>Restaurant : 75 couverts</li>
                                        <li>5 salles de séminaires : 140 personnes</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className="dividers" />
                </Container>
            </div>


            {/* /////////// BARRIERE /////////// */}

            <div id="barriere">
                <Container>
                    <Grid item md={12}>
                        <div>
                            <BarriereLogos className="logossvg" />
                            <h1>Le Grand Hôtel Dinard 5* <a href="https://www.hotelsbarriere.com/fr/dinard/le-grand-hotel.html" rel="noopener noreferrer" target="_blank"><img width="30px" src={VisitWebsite} alt="Grand Hotel Dinard" /></a></h1>
                            <hr />
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <p><span>Groupe Barrière</span> • Dinard (35)</p>
                                    <p>
                                        Principal établissement de la station balnéaire de la côte d’Emeraude, l'Hôtel de Dinard (collection Leading Hotel of the World) a été édifié en 1858 et reçoit régulièrement les têtes couronnées. 
                                        Il prendra le nom de Grand Hôtel à la fin du XIXe siècle. Son emplacement est exceptionnel. Il fait face aux remparts de Saint-Malo et domine la magnifique baie de la Vicomté.
                                    </p>
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}>
                                    <div className="position noreco">
                                        <p>2013 • <span>Réceptionniste</span></p>
                                        <p>Référence: Nicolas S. anciennement Assistant Chef de Réception<br /><span className="grey">Pour prise de référence, veuillez me contacter.</span></p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className="imagesinformations">
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="carousel">
                                <ImageGallery  autoPlay={true} slideInterval={"4000"} showBullets={true} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} items={imagesBarriere} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="extrainformations">
                                <h3>Le Grand Hôtel Dinard <br /> <span>en chiffres</span></h3>
                                <Divider />
                                <p className="nopaddingmargin">
                                    <ul>
                                        <li>Hôtel : 89 chambres</li>
                                        <li>Restaurant George V : 120 couverts</li>
                                        <li>Café 333 : 120 personnes</li>
                                        <li>5 salles de séminaires : 200 personnes</li>
                                    </ul>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

             <Footer />
        </div>
    );
};

export default HomePage;
