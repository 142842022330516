import React from "react";
import "./footer.css";
import Container from "@material-ui/core/Container";
import { Grid, Button} from "@material-ui/core";
import { ReactComponent as Divider } from '../../pages/Home/images/divider.svg';

const Footer = () => {

    return (
        <div className="footer">
            <Container>
                <Grid container id="footer-container">
                    <Grid item md={12} xs={12}>
                        <Divider className="footerdivider" />
                        <h2>Merci pour votre visite</h2><br />
                        <Button target="blank" fullWidth type="button" variant="contained" className="footerbutton" color="primary" href="https://www.linkedin.com/in/aurorehello" >Mon profil LinkedIn</Button>
                        <Button target="blank" fullWidth type="button" variant="contained" className="footerbutton" color="primary" href="mailto:aurorehello.pro@gmail.com" >Me contacter</Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;